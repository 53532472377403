import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.stock?.[3]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.stock?.[8]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.stock?.[1]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.stock?.[2]}")` }}
        ></figure>
        <div className="z-10 relative w-4/5 mx-auto pt-[380px] pb-[100px] md:pb-[150px] md:pt-[300px]  ">
          <div className="w-full flex flex-col md:grid grid-cols-2 justify-center items-center">
            <div className="w-[100%]">
              <h1 className="text-white text-left">
                {rpdata?.dbSlogan?.[4].slogan}
              </h1>
              <p className="text-white text-left">{rpdata?.dbValues?.[1].description}</p>
              <div className="flex justify-left">
                <ButtonContent />
              </div>
            </div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/EXPERIENCIA._Mesa%20de%20trabajo%201_Mesa%20de%20trabajo%201.png?alt=media&token=eb1bdd76-0ecc-48df-b917-44cabd565374"
              alt="years experience"
              className="w-[40%] md:w-full md:pb-10"
            />
          </div>

        </div>
      </div>
    </>
  );
}

export default HeroSection;
